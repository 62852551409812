import {
  AddNotificationGroupType,
  CheckUserAccess,
  OneClickAcknowledgement,
} from 'src/types/alarms';
import {
  api,
  ALARMS_URL,
  EVENTS,
  NOTIFICATIONS_URL,
  CUSTOMER_EVENTS,
  ACTIVE_EVENTS,
  NOTIFICATION_GROUPS_URL,
  CHECK_USERS_SITES_ACCESS,
} from '../api';

type QuickFilter = {
  id: number;
  label: string;
  param: string;
  value: string;
};

type FiltersType = {
  param: string;
};

type ExportAlarmsType = {
  customerId: string;
  customFileName: string;
  format: 'excel' | 'csv';
  quickFilter: QuickFilter;
  timezone: string;
  filters?: { base: FiltersType[]; tree: FiltersType[] };
};

const formatFilters = (filters?: { base: FiltersType[]; tree: FiltersType[] }) => {
  const filtersBaseValue: string = filters?.base
    ? filters.base.map((filter) => `&${filter.param}`).join('')
    : '';
  const filtersTreeValue: string = filters?.tree
    ? '&' + filters.tree.map((filter) => `${filter.param}`).join('&')
    : '';

  return {
    filtersBaseValue,
    filtersTreeValue,
  };
};

const getAll = async (
  customerId: string,
  limit?: number,
  skipTo?: string,
  siteId?: string,
  filters?: { base: FiltersType[]; tree: FiltersType[] }
) => {
  const skipToValue: string = skipTo && skipTo !== '' ? `&skipTo=${skipTo}` : '';
  const siteIdValue: string = siteId && siteId !== '' ? `&siteId=${siteId}` : '';
  const { filtersBaseValue, filtersTreeValue } = formatFilters(filters);
  const response = await api({
    method: 'get',
    url: limit
      ? `${ALARMS_URL}/${customerId}${CUSTOMER_EVENTS}?limit=${limit}${skipToValue}${siteIdValue}${filtersBaseValue}${filtersTreeValue}`
      : `${ALARMS_URL}/${customerId}${CUSTOMER_EVENTS}`,
  });

  return response.data;
};

const getAllBySiteId = async (
  customerId: string,
  siteId: string,
  fromDate: string,
  toDate: string
) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}?fromDate=${fromDate}&toDate=${toDate}`,
  });

  return response.data;
};

const getAlarmPopupInfo = async (customerId: string, siteId: string, eventId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}/${eventId}`,
  });

  return response.data;
};

const getAlarmPopupNotifications = async (customerId: string, eventId: string) => {
  const response = await api({
    method: 'get',
    url: `${NOTIFICATIONS_URL}/${customerId}/${eventId}`,
  });

  return response.data;
};

const acknowledgeAlarm = async (
  customerId: string,
  siteId: string,
  eventId: string,
  idToken: string
) => {
  const response = await api({
    method: 'post',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}/${eventId}/acknowledge`,
    headers: {
      'X-Streametric-User': idToken,
    },
  });

  return response.data;
};

const getAlarmCount = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}${EVENTS}/count`,
  });

  return response.data;
};

const getFilteredAlarmCount = async (
  customerId: string,
  quickFilter: QuickFilter,
  filters?: { base: FiltersType[]; tree: FiltersType[] }
) => {
  const { filtersBaseValue, filtersTreeValue } = formatFilters(filters);

  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/customer-events-count?${filtersBaseValue}${filtersTreeValue}`,
  });

  return response.data;
};

const getActiveAlarmCount = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}${ACTIVE_EVENTS}/count`,
  });

  return response.data;
};

const exportAlarms = async ({
  customerId,
  format,
  filters,
  timezone,
  customFileName,
}: ExportAlarmsType) => {
  const mimeType =
    format === 'excel'
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'text/csv';

  const { filtersBaseValue, filtersTreeValue } = formatFilters(filters);

  const timezoneParam = `timezone=${timezone}`;

  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/customer-events/export?${filtersBaseValue}${filtersTreeValue}${timezoneParam}`,
    responseType: 'blob',
    headers: {
      'media-type': mimeType,
    },
  });

  const contentDisposition = response.headers['content-disposition'];

  let fileName = 'download'; // Default name if not found
  if (customFileName !== '') {
    fileName = customFileName;
  } else if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = fileNameMatch[1];
    }
  }

  const blob = new Blob([response.data], { type: mimeType });

  // Step 2: Create a download link
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // Set the file name for the download

  // Step 3: Programmatically click the link to trigger the download
  document.body.appendChild(link);
  link.click();

  // Step 4: Clean up and remove the link
  document.body.removeChild(link);
  return response.data;
};

const getAllNotificationGroups = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}`,
  });

  return response.data;
};

const addNotificationGroup = async (customerId: string, data: AddNotificationGroupType) => {
  const response = await api({
    method: 'post',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}`,
    data,
  });

  return response.data;
};

const getNotificationGroupById = async (customerId: string, notificationId: string) => {
  const response = await api({
    method: 'get',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
  });

  return response.data;
};

const updateNotificationGroup = async (
  customerId: string,
  notificationId: string,
  data: AddNotificationGroupType
) => {
  const response = await api({
    method: 'patch',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
    data,
  });

  return response.data;
};

const deleteNotificationGroup = async (customerId: string, notificationId: string) => {
  const response = await api({
    method: 'delete',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
  });

  return response.data;
};

const checkUsersSitesAccess = async (data: CheckUserAccess) => {
  const response = await api({
    method: 'post',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${CHECK_USERS_SITES_ACCESS}`,
    data,
  });

  return response.data;
};

const oneClickAcknowledge = async (eventId: string, data: OneClickAcknowledgement) => {
  const response = await api({
    method: 'post',
    url: `/v2/events/${eventId}/token-acknowledge`,
    data,
  });

  return response.data;
};

export const AlarmsService = {
  getAll,
  getAllBySiteId,
  getAlarmPopupInfo,
  getAlarmPopupNotifications,
  acknowledgeAlarm,
  getAlarmCount,
  getActiveAlarmCount,
  getAllNotificationGroups,
  addNotificationGroup,
  checkUsersSitesAccess,
  getNotificationGroupById,
  updateNotificationGroup,
  deleteNotificationGroup,
  oneClickAcknowledge,
  getFilteredAlarmCount,
  exportAlarms,
};
