import { Element, FormSchema } from '../types';

type FormField = Record<string, any>;

const addField = (fieldName: string, formField: FormField, value: string = '') => {
  formField[fieldName] = value;
};

const addArrayField = (fieldName: string, formField: FormField, value: string[] = []) => {
  formField[fieldName] = value;
};

export const formatFormFields = (formSchema: FormSchema) => {
  const formFields: FormField = {};
  if (formSchema.dateType === 'date_range') {
    addField('date_to', formFields, new Date().toISOString());
  }
  addField('date_from', formFields, new Date().toISOString());
  addArrayField('assets', formFields, []);
  return formFields;
};

export const orderElements = (formSchema: FormSchema): Element[] =>
  formSchema.elements.sort((a, b) => a.order - b.order);
