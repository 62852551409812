import { FunctionComponent } from 'react';
import { getSettingsNavigationItems } from './siteItems';
import NavigationItem from './NavigationItem';
import { MenuPopover } from 'src/components';
import { useHtmlElement, useResponsive } from 'src/hooks';
import { Box, Stack, SvgIcon, useTheme, Typography } from '@mui/material';
import { ExpandMoreIcon } from 'src/assets';
import SettingsIcon from '@mui/icons-material/Settings';
import { StyledExpandMoreBox, StyledSettingsBox } from './style';
import { NavigationItemType } from './types';
import SettingsMenuPopoverItem from './SettingsMenuPopoverItem';
import { useAuthentication } from 'src/features/authentication/context';
import { ACCESS_ROLES } from 'src/config';

const SettingsNavigationItem: FunctionComponent<
  Omit<NavigationItemType, 'label'> & {
    isActive: boolean;
  }
> = (props) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const { addHtmlElement, htmlElement, removeHtmlElement } = useHtmlElement();

  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const getSettingsNavigationItemsBasedOnRole = () => {
    const currentRole = getCurrentRole(customerId, siteId);
    return getSettingsNavigationItems(currentRole as ACCESS_ROLES);
  };

  const settingsNavigationItems = getSettingsNavigationItemsBasedOnRole();

  const isSettingsActive =
    settingsNavigationItems.findIndex((settingsNavigationItem) =>
      window.location.pathname.includes(settingsNavigationItem.path)
    ) !== -1;
  return (
    <>
      <NavigationItem
        {...props}
        icon={
          isMobile ? (
            <Stack flexDirection="row">
              <StyledSettingsBox>
                <SvgIcon fontSize="medium" component={SettingsIcon} />
              </StyledSettingsBox>
              <StyledExpandMoreBox>
                <SvgIcon fontSize="small" component={ExpandMoreIcon} />
              </StyledExpandMoreBox>
            </Stack>
          ) : (
            SettingsIcon
          )
        }
        isActive={isSettingsActive}
        label={
          <Stack alignItems="center" flexDirection="row">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: isSettingsActive ? 'bold' : '400',
                [theme.breakpoints.down('md')]: {
                  fontSize: 12,
                },
              }}
            >
              Settings
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            >
              <SvgIcon component={ExpandMoreIcon} />
            </Box>
          </Stack>
        }
        onClick={(event: any) => {
          addHtmlElement(event.currentTarget as HTMLElement);
        }}
      />
      <MenuPopover
        onClose={() => {
          removeHtmlElement();
        }}
        open={htmlElement}
      >
        {settingsNavigationItems.map((settingsItem) => (
          <SettingsMenuPopoverItem settingsItem={settingsItem} key={settingsItem.path} />
        ))}
      </MenuPopover>
    </>
  );
};

export default SettingsNavigationItem;
